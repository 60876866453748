import React, {useCallback, useEffect, useRef} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {throttle, isEmpty, get} from 'lodash';
import MindsetsJsSDK, {
    InfoMessageAction,
    ErrorMessageAction,
    LoadChallengeVersionStreamEntitiesAction,
    LoadedChallengeVersionStreamEntitiesAction
} from "mindsets-js-sdk";

import StudentStreamUI from './StudentStreamUI'
import useAuth from "../../hooks/useAuth";
import {
    LoadStreamAction, SetStreamAction,
    SetStreamEntitiesResponsesAction,
    SetStudentStreamCurrentIndexAction
} from "../../actions/stream_actions";
import {storeAnswers} from "./MainContent/ProgressToolBar/utils";
import {store} from '../../index';

function streamUpdatedCallback(data) {
    const stream = store.getState().stream;
    const stream_x = {
        ...stream,
        index: data.index
    };
    store.dispatch(SetStreamAction(stream_x));
    setTimeout(() => {
        store.dispatch(SetStudentStreamCurrentIndexAction(data.index));
        window.scrollTo(0, 0);
    }, 500);
}

function lessonIndexUpdatedCallback(ref_components, fetchStreamThrottle) {
    const current_index = store.getState().student_stream_current_index;
    const card_ref_components = get(ref_components.current, current_index);
    Promise
        .resolve()
        .then(() => storeAnswers(card_ref_components, {must_be_valid: false}))
        .then(() => fetchStreamThrottle(true));
}

function lessonModeChangedCallback(ref_components, fetchStreamThrottle, fetchResponsesThrottle) {
    const stream = store.getState().stream;
    const current_index = store.getState().student_stream_current_index;
    const card_ref_components = get(ref_components.current, current_index);
    Promise
        .resolve()
        .then(() => storeAnswers(card_ref_components, {must_be_valid: false}))
        .then(() => fetchResponsesThrottle(stream))
        .then(() => fetchStreamThrottle(true));
}

export default function () {
    useAuth(true);

    const ref_components = useRef({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const stream = useSelector(state => state.stream || {});

    function fetchStream(updateIndex = true) {
        return dispatch(LoadStreamAction(params.code, {
            'selectors[0]': 'lesson_challengeVersion_challengeCode',
            'selectors[1]': 'lesson_user',
        })).then(stream => {
            if (updateIndex) {
                dispatch(SetStudentStreamCurrentIndexAction(stream.index));
            }

            return stream;
        });
    }

    function fetchChallengeStreamEntities(stream) {
        return dispatch(LoadChallengeVersionStreamEntitiesAction(stream.challenge_version.id));
    }

    /**
     * @deprecated Consider refactoring to actual responses. Why votes here?
     */
    function fetchResponses(stream) {
        return MindsetsJsSDK().Api.stream.content.index(
            stream.id,
            {
                order_by: 'id',
                order_direction: 'asc',
                'selectors[0]': 'responses',
                'selectors[1]': 'votes'
            }
        ).then(response => dispatch(SetStreamEntitiesResponsesAction(response.items)));
    }

    const fetchStreamThrottle = useCallback(throttle(fetchStream, 1500, {
        leading: false,
        trailing: true
    }), []);
    const fetchResponsesThrottle = useCallback(throttle(fetchResponses, 1500, {
        leading: false,
        trailing: true
    }), []);

    function moveToLatest(stream, stream_entities) {
        if (stream_entities.length) {
            dispatch(SetStudentStreamCurrentIndexAction(stream.index));
        }
    }

    useEffect(() => {
        fetchStream().then(stream => {
            return fetchChallengeStreamEntities(stream).then(stream_entities => {
                return fetchResponses(stream).then(() => moveToLatest(stream, stream_entities));
            });
        }).catch(error => {
            console.error(error);
            dispatch(ErrorMessageAction('Session not found, expired, deleted or does not belong to you', true));
            navigate('/student/home');
        });

        return () => {
            dispatch(SetStreamAction(null));
            dispatch(LoadedChallengeVersionStreamEntitiesAction([]));
            dispatch(SetStreamEntitiesResponsesAction([]));
            dispatch(SetStudentStreamCurrentIndexAction(0));
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(stream)) {
            const stream_channel = pusher.subscribe('stream_' + stream.id);
            stream_channel.bind('stream.updated', () => fetchStreamThrottle(false));
            stream_channel.bind('stream.deleted', () => {
                dispatch(InfoMessageAction('You have been removed from ' + stream.challenge_version.name + ' class', true));
                navigate('/student/home');
            });
            stream_channel.bind('stream.content.updated', streamUpdatedCallback);
            stream_channel.bind('responses.created', () => fetchResponsesThrottle(stream));
            stream_channel.bind('lesson.poll.updated', () => fetchResponsesThrottle(stream));

            const lesson_channel = pusher.subscribe('lesson_' + stream.lesson_id);
            lesson_channel.bind('lesson.updated', fetchStreamThrottle);
            lesson_channel.bind('lesson.mode.changed', () => lessonModeChangedCallback(ref_components, fetchStreamThrottle));
            lesson_channel.bind('lesson.index.updated', () => lessonIndexUpdatedCallback(ref_components, fetchStreamThrottle, fetchResponsesThrottle));

            pusher.connection.bind('state_change', states => {
                if ('connected' === states.current) {
                    fetchStreamThrottle();
                    fetchResponsesThrottle(stream);
                }
            });
        }

        return () => {
            if (!isEmpty(stream)) {
                const stream_channel = pusher.channel('stream_' + stream.id);
                if (stream_channel) {
                    stream_channel.unbind_all();
                    stream_channel.unsubscribe();
                }

                const lesson_channel = pusher.channel('lesson_' + stream.lesson_id);
                if (lesson_channel) {
                    lesson_channel.unbind_all();
                    lesson_channel.unsubscribe();
                }
            }
        }
    }, [stream.id, stream.status]);

    let page = null
    if (!isEmpty(stream)) {
        page = (
            <StudentStreamUI
                ref_components={ref_components.current}
            />
        )
    }

    return (
        <div id="student-stream">{page}</div>
    );
}
