import React from 'react';
import PropTypes from "prop-types";

import Pause from './Pause';
import Header from './Header';
import MainContent from './MainContent';

function StudentStreamUI({ref_components}) {
    return (
        <div>
            <Pause/>

            <Header/>

            <div>
                <MainContent
                    ref_components={ref_components}
                />
            </div>
        </div>
    );
}

StudentStreamUI.propTypes = {
    ref_components: PropTypes.object.isRequired
}

export default StudentStreamUI
